import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from 'react-bootstrap';
import LabelInput from "../../pages/Products/ProductWizard/ProductWizardPages/InputLable/Index";
import { FormikProps } from 'formik';

interface CADCurrencyTabProps {
  formik: FormikProps<any>;
}

const CADCurrencyTab: React.FC<CADCurrencyTabProps> = ({
  formik,
}) => {
  const {
    errors,
    values,
    values: {
      type,
      pricesCad,
      pricesCad: { product_prices, advertised_price_cad, quantity_min_cad, quantity_max_cad },
    },
    setFieldValue,
  } = formik;
  const cadPrice = product_prices?.find((price: { currency: string }) => price.currency === "CAD");
  const { advertised } = cadPrice || {};
  const supportedProductTypes = ["Day Tour", "Multi-day Tour"];
  const isPrivateDayTour = supportedProductTypes.includes(type);

  const [passengerTypes, setPassengerTypes] = useState(() => {
    const types = ["adult", "child", "infant"];
    if (type === "Day Tour" || type === "Multi-day Tour") {
      console.log("Initial passenger types:", types);
      return types;
    }
    if (pricesCad?.product_prices) {
      const cadPrice = pricesCad?.product_prices?.find((price: { currency: string }) => price.currency === "CAD");
      if (cadPrice?.prices) {
        const additionalPassengerTypes = Object.keys(cadPrice.prices).filter(
          (passengerType) => !types.includes(passengerType)
        );
        types.push(...additionalPassengerTypes);
      }
    }
    return types;
  });

  useEffect(() => {
    if (pricesCad) {
      if (!passengerTypes.includes("Custom")) {
        setPassengerTypes((prevPassengerTypes) => [...prevPassengerTypes]);
      }
    }
  }, [pricesCad]);

  const initialPricingData = pricesCad || {};
  const [customLabel, setCustomLabel] = useState("");
  console.log('initialPricingData:', initialPricingData);
  const passengerTypeMapping: { [key: string]: string } = {};
  passengerTypes.forEach((passengerType) => {
    passengerTypeMapping[passengerType.toLowerCase()] = passengerType;
  });

  const [priceArr, setPriceArr] = useState(() => ({ ...initialPricingData }));
  const updatePriceArray = () => {
    const updatedPrices: { [key: string]: any } = {};
    passengerTypes.forEach((passengerType) => {
      updatedPrices[passengerType] =
        initialPricingData[passengerTypeMapping[passengerType.toLowerCase()]] ||
        "";
    });
    return updatedPrices;
  };

  useEffect(() => {
    const updatedPrices = updatePriceArray();
    setPriceArr(updatedPrices);
  }, []);

  interface FormField {
    id: string;
    passengerType: string;
    value: any;
    label?: string;
  }

  const [formFields, setFormFields] = useState<FormField[]>(() => {
    let fields = [
      {
        id: "adult",
        passengerType: "adult",
        value: priceArr.Adults,
      },
    ];

    if (passengerTypes.length > 0) {
      fields = passengerTypes
        .filter((passengerType) => {
          const value = priceArr[passengerType];
          return value !== null && value !== undefined && value !== "";
        })
        .map((passengerType) => {
          if (passengerType.startsWith("Custom")) {
            const [customLabel, value] = passengerType.split(":");
            return {
              id: passengerType,
              passengerType: customLabel,
              value: priceArr[passengerType],
              label: customLabel,
            };
          }
          return {
            id: passengerType,
            passengerType,
            value: priceArr[passengerType],
            label: passengerType === "Custom Price" ? "" : passengerType,
          };
        });
    }

    return fields;
  });

  if (formFields.length === 0) {
    const adultsField = {
      id: "adult",
      passengerType: "adult",
      value: priceArr.Adults,
    };
    formFields.push(adultsField);
  }

  const [isCustomPricing, setIsCustomPricing] = useState(false);
  const handleAddField = () => {
    let newPassengerType = "";

    if (isCustomPricing && customLabel) {
      const nextCustomPriceIndex =
        formFields.filter((field) =>
          field.passengerType.startsWith("Custom Price")
        ).length + 1;

      newPassengerType = `Custom Price ${nextCustomPriceIndex}`;
    } else if (type === "Private Day Tour") {
      newPassengerType = `Custom ${formFields.length + 1}`;
    } else {
      const availablePassengerTypes = passengerTypes.filter(
        (type) =>
          !formFields.some(({ passengerType }) => passengerType === type)
      );

      if (availablePassengerTypes.length > 0) {
        [newPassengerType] = availablePassengerTypes;
      } else {
        alert(
          type === "Private Day Tour"
            ? "You have reached the limit of select options for Private Day Tours"
            : "You have reached the limit of select options"
        );
        return;
      }
    }
    const newField = {
      id: newPassengerType,
      passengerType: newPassengerType,
      value: "",
    };

    const updatedFields = [...formFields, newField];
    setFormFields(updatedFields);
  };

  const handleRemoveField = (passengerType: any) => {
    const updatedFields = formFields.filter(
      (field) => field.passengerType !== passengerType
    );
    const updatedPriceArr = { ...priceArr };
    delete updatedPriceArr[passengerType];
    setFormFields(updatedFields);
    setPriceArr(updatedPriceArr);
    currency
    setFieldValue("currency", "CAD");
    setFieldValue("pricesCad.priceArr", updatedPriceArr);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, passengerType: string, value: string) => {
    if (!passengerType) return;

    const updatedPriceArr = { ...priceArr };
    let updatedFields = [...formFields];

    if (passengerType.startsWith("Custom")) {
      updatedFields.forEach((formField, index) => {
        if (formField.passengerType === passengerType) {
          updatedFields[index] = {
            ...formField,
            label: customLabels[passengerType] || "",
            value,
          };
        }
      });

      updatedPriceArr[passengerType] = value;
    } else if (value === "") {
      updatedFields = updatedFields.filter(
        (formField) => formField.passengerType !== passengerType
      );
      delete updatedPriceArr[passengerType];
    } else {
      updatedFields.forEach((formField, index) => {
        if (formField.passengerType === passengerType) {
          updatedFields[index] = {
            ...formField,
            value,
          };
        }
      });
      updatedPriceArr[passengerType] = value;
    }

    setFormFields(updatedFields);
    setPriceArr(updatedPriceArr);
    setFieldValue("currency", "CAD");
    setFieldValue("pricesCad.priceArr", updatedPriceArr);
  };

  const [customLabels, setCustomLabels] = useState<{ [key: string]: string }>({});

  const handlePassengerTypeChange = (event: React.ChangeEvent<HTMLSelectElement>, field: any) => {
    const selectedPassengerType = event.target.value;

    if (selectedPassengerType !== field.passengerType) {
      if (
        formFields.some(
          (formField) => formField.passengerType === selectedPassengerType
        )
      ) {
        alert(`"${selectedPassengerType}" is already selected.`);
        return;
      }

      const updatedFields = formFields.map((formField) => {
        if (formField.id === field.id) {
          const label =
            selectedPassengerType === "Custom Price"
              ? ""
              : selectedPassengerType;
          return {
            ...formField,
            passengerType: selectedPassengerType,
            id: selectedPassengerType,
            label,
          };
        }
        return formField;
      });

      const updatedPriceArr = { ...priceArr };
      updatedPriceArr[selectedPassengerType] = field.value;
      if (field.passengerType) {
        delete updatedPriceArr[field.passengerType];
      }
      setFormFields(updatedFields);
      setPriceArr(updatedPriceArr);
      setFieldValue("currency", "CAD");
      setFieldValue("pricesCad.priceArr", updatedPriceArr);
    }
  };

  const handleCustomLabelChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: any,
    index: number,
    label: string
  ) => {
    const newCustomLabel = label;
    const updatedCustomLabels = { ...customLabels };
    updatedCustomLabels[field.passengerType] = newCustomLabel;
    setCustomLabels(updatedCustomLabels);
  
    const updatedFields = formFields.map((formField) => {
      if (formField.id === field.id) {
        return {
          ...formField,
          passengerType: `custom:${index}:${newCustomLabel}`,
          id: `custom:${index}:${newCustomLabel}`,
          label: newCustomLabel,
        };
      }
      return formField;
    });
  
    // Update priceArr and pricesCad.priceArr without creating duplicates
    const updatedPriceArr = { ...priceArr };
    const updatedPriceArrCad = { ...priceArr }; // Update CAD price array separately
  
    const existingCustomKey = Object.keys(updatedPriceArr).find(
      (key) => key === field.passengerType
    );
  
    if (existingCustomKey) {
      // If a custom price exists, update it without duplicating
      delete updatedPriceArr[existingCustomKey];
      delete updatedPriceArrCad[existingCustomKey];
    }
  
    updatedPriceArr[`custom:${index}:${newCustomLabel}`] = field.value;
    updatedPriceArrCad[`custom:${index}:${newCustomLabel}`] = field.value;
  
    setFormFields(updatedFields);
    setPriceArr(updatedPriceArr);
  
    // Update both currency and pricesCad fields
    setFieldValue("currency", "CAD");
    setFieldValue("pricesCad.priceArr", updatedPriceArrCad);
  };
  
  const [currency, setCurrency] = useState("");
  return (
    <div style={{ padding: "10px", color: 'black' }}>
      <div>
        {formFields.map((field, index) => (
          <div key={`${field.id}-${index}`} style={{ display: "flex", marginBottom: "10px" }}>
            <Form.Group style={{ display: "flex" }}>
              <div>
                <Form.Select
                  name={`selectPassengerType${field.id}`}
                  className="value custom-options"
                  aria-label={`Select Passenger Type ${field.id}`}
                  onChange={(event) => handlePassengerTypeChange(event, field)}
                  value={field.passengerType}
                >
                  {!["Day Tour", "Multi-day Tour"].includes(type) && (
                    <option key={`option-CustomPrice-${field.id}`} value="Custom Price">
                      Custom Price
                    </option>
                  )}
                  {passengerTypes
                    .filter(
                      (passengerType) =>
                        passengerType !== "Custom Price" || passengerType.startsWith("custom:")
                    )
                    .filter((passengerType) => {
                      if (!["Day Tour", "Multi-day Tour"].includes(type)) {
                        return !passengerType.startsWith("Custom");
                      }
                      return true;
                    })
                    .map((passengerType, index, array) => (
                      <option key={`option-${passengerType}-${field.id}`} value={passengerType}>
                        {passengerType.startsWith("custom:") ? `Custom${index}` : passengerType}
                      </option>
                    ))}
                </Form.Select>
              </div>

              {/* Additional custom label input */}
              {![
                "adult",
                "child",
                "infant",
                "Passenger 1",
                "Passenger 2",
                "Passenger 3",
                "Passenger 4",
                "Passenger 5",
                "Passenger 6",
                "Passenger 7",
              ].includes(field.passengerType) && (
                  <div style={{ marginLeft: "30px" }}>
                    <LabelInput field={field} index={index} handleCustomLabelChange={handleCustomLabelChange} />
                  </div>
                )}

              <div style={{ marginLeft: "30px" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id={`basic-addon-price-${field.id}`}>
                    {currency === "USD" ? "US" : "CAD"}
                  </InputGroup.Text>
                  <Form.Control
                    name={`inputPassengerPrice${field.id}`}
                    type="number"
                    required
                    value={field.value}
                    onChange={(event) => handleInputChange(event as React.ChangeEvent<HTMLInputElement>, field.passengerType, (event.target as HTMLInputElement).value)}
                    placeholder="Enter Price"
                    aria-label="Price"
                    aria-describedby={`basic-addon-price-${field.id}`}
                  />
                </InputGroup>
              </div>

              <div style={{ marginLeft: "10px" }}>
                {index === 0 ? (
                  <Button variant="outline-secondary" id={`button-addon-${field.id}`} onClick={handleAddField}>
                    +
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outline-secondary"
                      id={`button-addon-${field.id}-1`}
                      onClick={() => handleRemoveField(field.passengerType)}
                    >
                      -
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="outline-secondary"
                      id={`button-addon-${field.id}-2`}
                      onClick={handleAddField}
                    >
                      +
                    </Button>
                  </>
                )}
              </div>
              {errors.priceArr && (
                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        ))}
      </div>

      <div style={{ width: "300px" }}>
        <Form.Label>Advertised Price</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>CAD</InputGroup.Text>
          <Form.Control
            name="pricesCad.advertised_price_cad"
            value={advertised_price_cad || ""}
            type="number"
            onChange={(event) => {
              setFieldValue("pricesCad.advertised_price_cad", event.target.value);
              setFieldValue("currency", "CAD");
              setFieldValue("pricesCad.priceArr", priceArr);
            }}
            placeholder="Enter Advertised Price"
          />
        </InputGroup>
        <div style={{ color: "red" }}>{errors.advertised_price_cad}</div>
      </div>

    </div>
  );
};

export default CADCurrencyTab;